<style lang="scss">
.slidercontainer {
  overflow-x: hidden;
  .slideritemcontainer {
    display: flex;
    .slideritem {
      display: inline-block;
    }
  }
}

.swipe-txt {
  text-align: center;
  font-size: 90%;
  opacity: 0.8;
  display: none;
}

.entryslider-container {
  position: relative;
  //border:1px solid blue;
  margin-bottom: 5px;
  .arrow-navi {
    width: 100%;
    position: absolute;
    top: 50%;
    margin-top: -25px;
    z-index: 100;
    .arrow-btn {
      width: 30px;
      cursor: pointer;
      svg {
      }
      &:hover {
        opacity: 0.7;
      }
    }
    .arrow-left {
      position: absolute;
      right: 15px;
      top: 0;
    }
    .arrow-right {
      position: absolute;
      left: 15px;
      top: 0;
    }
  }
  &.hasDescription {
    .entryslider-container-text {
      padding: 8px 5px;
      font-style: italic;
      font-size: 90%;
    }
  }
}
</style>

<template>
  <div class="slidercontainer" ref="viewport" @mouseenter="stopanimation" @mouseleave="startanimation" @touchstart="touchstart" @mousedown="touchstart" @touchend="touchend" @mouseup="touchend" @mousemove="touchmove" @touchmove="touchmove">
    <slot name="pagination" :numItems="itemcount" :moveto="moveto" :active="active"></slot>
    <div class="slideritemcontainer" ref="container" :style="containerstyle">
      <slot name="items"></slot>
    </div>
    <div class="swipe-txt">Linke Maustaste gedrückt halten und nach links und rechts swipen.</div>
    <slot name="navigation" :left="left" :right="right"></slot>
  </div>
</template>

<script lang="ts">
import { Emitter } from 'mitt';
import { computed, defineComponent, ref, onMounted, onUnmounted, inject, PropType } from 'vue';

type ItemsDefintion = {
  [propName: number]: number;
};

export default defineComponent({
  props: {
    items: { type: [Number, Object] as PropType<ItemsDefintion | number>, default: 1 },
    speed: { type: Number, default: 1000 },
    mousestops: { type: Boolean, default: false },
    delay: { type: Number, default: 5000 },
    autoplay: { type: Boolean, default: false },
    easing: { type: String, default: 'ease-in-out' },
    noclone: { type: Boolean, default: false },
  },
  emits: ['activechanged', 'rollover', 'start', 'stop'],
  setup(props) {
    //@tsignore
    const mitt = inject('emitter') as Emitter<any>;

    const swiping = ref(0);

    const windowsize = ref(window.innerWidth);
    window.addEventListener('resize', () => {
      windowsize.value = window.innerWidth;
      mitt.emit('resetSlider');
    });

    const viewport = ref<HTMLElement>();
    const container = ref<HTMLElement>();
    const containerwidth = ref(0);

    const itemsshown = computed(() => {
      if (typeof props.items === 'object' && props.items !== null) {
        let items: { width: number; items: number } = { width: 0, items: 1 };
        for (const resolution in props.items) {
          if (windowsize.value >= parseInt(resolution) && windowsize.value >= items.width) {
            items = { width: parseInt(resolution), items: props.items[resolution] };
          }
        }
        return items.items;
      } else {
        return props.items as number;
      }
    });

    const itemwidth = computed(() => containerwidth.value / itemsshown.value);
    const itemcount = ref(0);

    const itemcontainerwidth = ref(0);
    const offset = ref(0);
    const isRunning = ref(false);

    let animation: Animation;
    let interval: number;
    let reseting = false;

    const translate = computed(() => {
      let preview = 0;
      if (swiping.value == 1) {
        preview = -0;
      } else if (swiping.value == -1) {
        preview = 0;
      }
      return offset.value * itemwidth.value + preview;
    });
    const containerstyle = computed(() => {
      return `transform: translateX(${translate.value}px); width: ${itemcontainerwidth.value}px`;
    });
    const active = computed(() => 1337);

    const animate = (from: number, to: number) => {
      if (container.value) {
        isRunning.value = true;
        animation = container.value.animate([{ transform: `translateX(${from * itemwidth.value}px)` }, { transform: `translateX(${to * itemwidth.value}px)` }], { duration: props.speed, easing: props.easing });
        animation.finished.then(() => {
          isRunning.value = false;

          if (!props.noclone) {
            if (offset.value <= -itemsshown.value - itemcount.value) {
              offset.value = -itemsshown.value;
            } else if (offset.value >= 0) {
              offset.value = -itemcount.value;
            }
          }
        });
      }
    };

    const right = () => {
      console.log('right');
      if (isRunning.value) return;
      if (props.noclone && offset.value >= 0) {
        return;
      }
      offset.value++;
      animate(offset.value - 1, offset.value);
    };

    const left = () => {
      console.log('left');
      if (isRunning.value) return;
      if (props.noclone && offset.value <= itemsshown.value - itemcount.value) {
        return;
      }
      offset.value--;
      animate(offset.value + 1, offset.value);
    };

    const moveto = (to: number) => {
      const from = offset.value;
      offset.value = -to - itemsshown.value + 1;
      animate(from, -to - itemsshown.value + 1);
    };

    const init = () => {
      if (container.value !== undefined && viewport.value !== undefined) {
        const items = container.value.getElementsByClassName('slideritem');
        itemcount.value = items.length;
        containerwidth.value = viewport.value.clientWidth;

        let overflowadded = 0;
        for (let i = 0; i < itemcount.value; i++) {
          const item = items[i + overflowadded];
          (item as HTMLElement).style.width = `${itemwidth.value}px`;
          if (i < itemsshown.value && !props.noclone) {
            const node = item.cloneNode(true) as HTMLElement;
            node.classList.add('overflow');
            container.value.appendChild(node);
          }

          if (itemcount.value - i <= itemsshown.value && !props.noclone) {
            overflowadded++;
            const node = item.cloneNode(true) as HTMLElement;
            node.classList.add('overflow');
            container.value.insertBefore(node, container.value.childNodes[overflowadded]);
            //  container.value.insertBefore(item.cloneNode(true));
          }
        }

        if (!props.noclone) {
          offset.value = -itemsshown.value;
        } else {
          offset.value = 0;
        }

        itemcontainerwidth.value = items.length * itemwidth.value;
        if (props.autoplay) {
          interval = setInterval(() => {
            left();
          }, props.delay);
        }

        reseting = false;
      }
    };

    onMounted(() => setTimeout(init, 500));
    onUnmounted(() => clearInterval(interval));

    let touchstartx = 0;

    const unify = (e: TouchEvent | MouseEvent) => {
      return (e as TouchEvent).changedTouches ? (e as TouchEvent).changedTouches[0] : (e as MouseEvent);
    };

    const touchstart = (e: TouchEvent | MouseEvent) => {
      // e.preventDefault();

      touchstartx = unify(e).clientX;
    };

    const touchend = (e: TouchEvent | MouseEvent) => {
      // e.preventDefault();

      if (touchstartx || touchstartx === 0) {
        if (Math.abs(unify(e).clientX - touchstartx) > 50) {
          const direction = Math.sign(unify(e).clientX - touchstartx);
          if (direction == -1) {
            left();
          } else if (direction == 1) {
            right();
          }
        }
        swiping.value = 0;
        touchstartx = 0;
      }
    };

    const touchmove = (e: TouchEvent | MouseEvent) => {
      if (Math.abs(unify(e).clientX - touchstartx) > 50) {
        if (e.cancelable) {
          e.preventDefault();
        }
        if (touchstartx || touchstartx != 0) {
          swiping.value = Math.sign(unify(e).clientX - touchstartx);
        }
      }
    };

    const stopanimation = () => {
      if (props.mousestops && animation !== undefined) {
        animation.pause();
      }
    };
    const startanimation = (e: TouchEvent | MouseEvent) => {
      if (touchstartx) {
        touchend(e);
      }
      if (isRunning.value) animation.play();
    };

    const reset = () => {
      if (reseting) return;
      reseting = true;
      clearInterval(interval);
      isRunning.value = false;
      containerwidth.value = 0;
      itemcontainerwidth.value = 0;
      offset.value = 0;
      if (container.value) {
        const nodes = container.value.querySelectorAll('.overflow');
        for (const node of nodes) {
          node.remove();
        }
      }
      setTimeout(init, 250);
    };

    let resetTimeout: number;
    mitt.on('resetSlider', () => {
      clearTimeout(resetTimeout);
      resetTimeout = setTimeout(reset, 250);
    });

    return {
      viewport,
      container,
      offset,
      left,
      right,
      stopanimation,
      startanimation,
      containerstyle,
      itemcount,
      moveto,
      active,
      itemsshown,
      touchstart,
      touchend,
      touchmove,
    };
  },
});
</script>
